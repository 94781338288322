<template>
    <div class="container-fluid">
      <header class="d-flex justify-content-center py-3">
        <ul class="nav nav-pills">
          <li class="nav-item">
            <router-link to="/" class="nav-link" active-class="active" aria-current="page">Home</router-link>
          </li>
          <li class="nav-item">
            <a @click.prevent="handleAboutClick" class="nav-link" :class="{ active: isActive('/about') }">About</a>
          </li>
          <li class="nav-item">
            <router-link to="/addbook" class="nav-link" active-class="active">Add Book</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/FireLogin" class="nav-link" active-class="active">Firebase Login</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/FireRegister" class="nav-link" active-class="active">Firebase Register</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/GetBookCount" class="nav-link" active-class="active">Get Book count</router-link>
          </li>
          <li>
            <router-link to="/WeatherCheck" class="nav-link" active-class="active">Get Weather</router-link>
          </li>
          <li>
            <router-link to="/CountBookAPI" class="nav-link" active-class="active">Count Book API</router-link>
          </li>
        </ul>
        <div v-if="isAuthenticated" class="ml-auto">
            <button @click="handleLogout" class="btn btn-secondary">Logout</button>
        </div>
      </header>
    </div>
  </template>
  
  <script>
  import { isAuthenticated } from '../router';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'BHeaderComponent',
    setup() {
      const router = useRouter();
  
      const handleAboutClick = () => {
        if (isAuthenticated.value) {
          router.push('/about');
        } else {
          router.push('/login');
        }
      };

      const handleLogout = () => {
      isAuthenticated.value = false;
      router.push('/login');
    };
  
      const isActive = (path) => {
        return router.currentRoute.value.path === path;
      };
  
      return {
        isAuthenticated,
        handleAboutClick,
        isActive,
        handleLogout
      };
    }
  };
  </script>
  
  <style scoped>
  .b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.1);
    border: solid rgba(0, 0, 0, 0.15);
    border-width: 1px 0;
    box-shadow:
      inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
      inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
  }
  
  .form-control-dark {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: var(--bs-gray);
  }
  .form-control-dark:focus {
    color: #fff;
    background-color: var(--bs-dark);
    border-color: #fff;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
  }

  .logout-button {
  cursor: pointer;
  color: red;
  margin-left: 20px;
}
  </style>